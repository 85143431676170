import countVCTElement from './countVCTElement';

export default function getVCTScript() {
  // non minified vctScript:
  /*
    ${countVCTElement};
    var image = new Image();
    image.onload = window.markVisuallyComplete;
    image.src = document.querySelector('[data-aid="BACKGROUND_IMAGE_RENDERED"]').currentSrc;
  */
  /* eslint-disable-next-line max-len */
  return `${countVCTElement};var i=new Image();i.onload=window.markVisuallyComplete;i.src=document.querySelector('[data-aid="BACKGROUND_IMAGE_RENDERED"]').currentSrc;`;
}
